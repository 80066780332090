import React from "react"
import "../style/profile.css"
import ProfileCard from "../components/profileCard/ProfileCard"
import MiniNav from "../components/miniNav/MiniNav"
import ReviewCard from "../components/reviewCard/ReviewCard"
import About from "../components/about/About"
// import BookingCard from "../components/bookingCard/BookingCard"
import { InlineWidget } from "react-calendly"
import Seo from "../components/seo"
import PageNav from "../components/homeNav/PageNav"
import Logo from "../images/Afyabook.svg"

export default function Profile() {
  return (
    <div>
    <Seo
    title="Consult on medication therapy | Afyabook"
    description="Need more information on your medication use, side effects, medication therapy management and remote patient monitoring? Consult Dr. Ibrahim Okich"
    img={Logo}
    keywords="medication therapy management, medication side effects, medication use, remote patient monitoring, medication advice, pharmacist, clinical pharmacist, pharmacy"
    siteUrl="https://www.afyabook.com/Profile"
    canonical="https://www.afyabook.com/Profile"
  ></Seo>
  <PageNav />
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-9">
            <div class="row justify-content-evenly">
              <div class="col-10">
                {/* <ProfileCard /> */}
                {/* <MiniNav /> */}
              </div>
              <div  className="col-12">
                {/* <ReviewCard /> */}
                {/* <About /> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div className="calendar">
              <InlineWidget
                url="https://calendly.com/tripleaim"
                styles={{
                  height: "1000px",
                  width: "100%",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
